<template>
    <div class="construction-projects-view">
        <div class="l-inline l-spread l-gap-2 filter-actions">
            <div class="l-inline l-center-v l-gap-2">
                <i18n path="showOf">
                    <b>{{ filteredItems.length }}</b>
                    <b>{{ items.length }}</b>
                </i18n>

                <a v-if="isClearFilterVisible" @click="$emit('clearFilter')">
                    {{ $t('clearFilter') }}
                </a>
            </div>

            <IconButton
                @click="$router.push({ name: 'constructionProjectCreate' })"
            >
                <AddIcon />
            </IconButton>
        </div>

        <table v-infinite-scroll="loadMoreItems" infinite-scroll-distance="25">
            <thead>
                <tr>
                    <th v-for="column in columns" :key="column">
                        {{ $t('columns.' + column) }}

                        <IconButton @click="handleSort(column)">
                            <SortArrowIcon
                                :direction="
                                    sortBy === column ? sortDirection : 0
                                "
                                width="10"
                                height="10"
                            />
                        </IconButton>
                    </th>
                </tr>
            </thead>

            <tr
                v-for="item in itemsPortion"
                :key="item.id"
                @click="handleRowClick(item.id)"
            >
                <td v-for="column in columns" :key="column">
                    {{ getColumnValue(column, item) }}
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import infiniteScroll from 'vue-infinite-scroll'

import AddIcon from '@/components/icons/AddIcon'
import IconButton from '@/components/IconButton'
import SortArrowIcon from '@/components/icons/SortArrowIcon'

const defaultScrollLimit = 15
const defaultSortColumn = 'name'
const defaultSortDirection = 1

export default {
    name: 'ConstructionProjectsTableView',
    components: {
        AddIcon,
        IconButton,
        SortArrowIcon,
    },
    directives: {
        infiniteScroll,
    },
    props: {
        filterLocation: {
            type: Array,
            default: () => [],
        },
        filterSearch: {
            type: String,
            default: '',
        },
        items: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            columns: ['reference', 'name', 'address', 'location'],
            scrollLimit: defaultScrollLimit,
            scrollStep: defaultScrollLimit,
            sortBy: defaultSortColumn,
            sortDirection: defaultSortDirection,
        }
    },
    computed: {
        ...mapState('location', ['locations']),
        filteredItems() {
            let result = this.items

            if (this.filterSearch) {
                const query = this.filterSearch.toLowerCase()
                result = result.filter(item =>
                    ['reference', 'name', 'address'].some(column =>
                        String(this.getColumnValue(column, item))
                            .toLowerCase()
                            .includes(query)
                    )
                )
            }

            if (this.filterLocation.length) {
                result = result.filter(item =>
                    this.filterLocation.some(
                        location => location.id === item.location
                    )
                )
            }

            if (this.sortBy) {
                result = [...result].sort((a, b) => {
                    const aValue = this.getColumnValue(this.sortBy, a)
                    const bValue = this.getColumnValue(this.sortBy, b)

                    if (aValue === bValue) {
                        return 0
                    } else if (aValue == null) {
                        return 1
                    } else if (bValue == null) {
                        return -1
                    } else if (typeof aValue === 'string') {
                        return aValue.localeCompare(bValue) * this.sortDirection
                    }

                    return aValue > bValue
                        ? this.sortDirection
                        : -this.sortDirection
                })
            }

            return result
        },
        itemsPortion() {
            return this.filteredItems.length === this.items.length
                ? this.filteredItems.slice(0, this.scrollLimit)
                : this.filteredItems
        },
        isClearFilterVisible() {
            return this.filterLocation.length || this.filterSearch
        },
    },
    methods: {
        getColumnValue(column, item) {
            switch (column) {
                case 'address':
                    return item.address
                case 'location':
                    return this.locations.find(
                        location => location.id === item.location
                    )?.name
                case 'name':
                    return item.name
                case 'reference':
                    return item.reference
                case 'type':
                    return item.asset_details.asset_type_identifier
            }
        },
        handleRowClick(id) {
            this.$router.push({
                name: 'constructionProjectDetails',
                params: { id },
            })
        },
        handleSort(column) {
            if (this.sortBy !== column || this.sortDirection !== 1) {
                this.sortBy = column
                this.sortDirection = 1
            } else if (this.sortDirection === 1) {
                this.sortDirection = -1
            }
        },
        loadMoreItems() {
            if (this.scrollLimit < this.items.length) {
                this.scrollLimit += this.scrollStep
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "columns": {
            "address": "Address",
            "location": "Location",
            "name": "Name",
            "reference": "Auftragnummer"
        },
        "clearFilter": "Clear filter",
        "showOf": "Show {0} of {1}"
    },
    "de": {
        "columns": {
            "address": "Addresse",
            "location": "Standort",
            "name": "Name",
            "reference": "Auftragnummer"
        },
        "clearFilter": "Filter zurücksetzen",
        "showOf": "Zeige {0} von {1}"
    },
    "fr": {
        "columns": {
            "address": "Adresse",
            "location": "Emplacement",
            "name": "Nom",
            "reference": "Numéro de commande"
        },
        "clearFilter": "Supprimer le filtre",
        "showOf": "Afficher {0} de {1}"
    },
    "it": {
        "columns": {
            "address": "Address",
            "location": "Locazione",
            "name": "Name",
            "reference": "Auftragnummer"
        },
        "clearFilter": "Annullare il filtro",
        "showOf": "Listo {0} di {1}"
    }
}
</i18n>

<style lang="scss" scoped>
.construction-projects-view {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.filter-actions {
    position: sticky;
    top: 0;
    left: 0;
    padding: 1rem 2rem;
    height: 52px;
    background-color: #fff;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
    z-index: 1;

    a {
        font-size: 13px;
        color: #000;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }
}

table {
    width: 100%;
    border-spacing: 0;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.7);

    thead {
        position: sticky;
        top: 52px;
        background-color: #fff;
        z-index: 1;

        .icon-button {
            margin-left: 5px;
        }
    }

    tr {
        height: 100%;

        &:not(:first-child) {
            cursor: pointer;

            &:hover {
                background-color: $color-gray-lighter;
            }

            & + tr {
                td {
                    border-top: $style-border;
                }
            }
        }

        th {
            padding: 1rem;
            border-top: $style-border;
            border-bottom: $style-border;
            text-align: left;
        }

        td {
            padding: 0.5rem 1rem;
        }

        th,
        td {
            white-space: nowrap;

            &:first-child {
                padding-left: 2rem;
            }

            &:not(:last-child) {
                width: 1%;
            }
        }
    }
}
</style>
